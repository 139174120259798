import React from 'react';
import {Navbar} from "react-bootstrap";
import {NavbarJedha} from '../style/style';

const NavBar = () =>{

    return (
        <NavbarJedha className="p-0 d-flex justify-content-center">
            <Navbar.Brand>
            <img
                alt=""
                src="https://drive.google.com/uc?export=view&id=1ewGK8XvveI6i1iuanZ8qD7eMcLdUOkkJ"
                height="75"
                width="75"
                className="d-inline-block align-top p-0"
            />{' '}
            </Navbar.Brand>
        </NavbarJedha>
    );
};

export default NavBar;