import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { StepJedha, ConfirmationJedha } from "../style/style";

const Confirmation = () => {
  return (
    <div className="col-sm-10 col-md-8 m-auto">
      <div className="d-none ml-auto mr-auto mt-4 d-md-flex justify-content-center">
        <StepJedha.Group unstackable fluid>
          <StepJedha completed>
            <Icon name="user" />
            <StepJedha.Content>
              <StepJedha.Title>Registration information</StepJedha.Title>
            </StepJedha.Content>
          </StepJedha>
          <StepJedha active>
            <Icon name="info" />
            <StepJedha.Content>
              <StepJedha.Title>Confirmation</StepJedha.Title>
            </StepJedha.Content>
          </StepJedha>
        </StepJedha.Group>
      </div>
      <ConfirmationJedha className="col-12 p-3 ml-auto mr-auto mt-5 d-flex flex-column justify-content-center">
        <h1 className="text-center">
          <span role="img" aria-label="rocket">
            🚀
          </span>{" "}
          Welcome!{" "}
          <span img="role" aria-label="rocket">
            🚀
          </span>
        </h1>
        <p className="text-center">
          Thank you for taking the time to fill out the form. We have received
          your information.
        </p>

        <h2 className="text-center">
          <span role="img" aria-label="wave">
            👋
          </span>{" "}
          The following
        </h2>
        <p className="text-center">
          You will receive your contract by email to sign electronically. In the
          meantime, don't hesitate to visit our social networks{" "}
        </p>

        <div className="justify-content-center">
          <Button
            color="facebook"
            className="m-2"
            href="https://www.facebook.com/jedhaBootcamp/"
          >
            <Icon name="facebook" /> Facebook
          </Button>
          <Button
            color="youtube"
            className="m-2"
            href="https://www.youtube.com/channel/UC_eP8EL0XZYwmTf1_m1U6-Q"
          >
            <Icon name="youtube" /> YouTube
          </Button>
          <Button
            color="linkedin"
            className="m-2"
            href="https://www.linkedin.com/school/jedhabootcamp/"
          >
            <Icon name="linkedin" /> LinkedIn
          </Button>
          <Button
            color="instagram"
            className="m-2"
            href="https://www.instagram.com/jedhabootcamp/"
          >
            <Icon name="instagram" /> Instagram
          </Button>
          <Button
            color="twitter"
            className="m-2"
            href="https://twitter.com/JedhaBootcamp"
          >
            <Icon name="twitter" /> Twitter
          </Button>
        </div>
      </ConfirmationJedha>
    </div>
  );
};

export default Confirmation;
