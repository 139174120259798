import React from 'react';
import NavBar from './components/navBar';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {BrowserRouter, Route, Switch, } from 'react-router-dom';
import CollectUserInfo from './components/collectUserInfo';
import Confirmation from './components/confirmation';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  return (
    <BrowserRouter>
      <div>  
          <NavBar />
          <Elements stripe={stripePromise}>
              <Switch>
                <Route exact path="/" component={CollectUserInfo}/>
                <Route exact path="/confirmation" component={Confirmation} />
                <Route component={CollectUserInfo}/>
              </Switch>
          </Elements>
      </div>
  </BrowserRouter>
  );
}

export default App;
