import React, { useState } from "react";
import { IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Row, Col } from "react-bootstrap";
import { Icon } from "semantic-ui-react";
import {
  StepJedha,
  FormJedha,
  Fieldset,
  FieldTitle,
  Label,
  Input,
  FormSubmit,
  ReferenceSepa,
} from "../style/style";
import { useHistory } from "react-router-dom";
import axios from "axios";

const CollectUserInfo = () => {
  const stripe = useStripe();
  const history = useHistory();
  const elements = useElements();

  const userInfo = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    postCode: "",
  };
  const [values, setValues] = useState(userInfo);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create a Customer on Stripe
    const createCustomerRequest =
      process.env.REACT_APP_API_LINK + "create-customer";
    axios
      .post(
        createCustomerRequest,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          address: values.address,
          city: values.city,
          postCode: values.postCode,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        const client_secret = response["data"]["client_secret"];
        const client_id = response["data"]["client_id"];

        const ibanElement = elements.getElement(IbanElement);

        const payment_method = stripe
          .confirmSepaDebitSetup(client_secret, {
            payment_method: {
              sepa_debit: ibanElement,
              billing_details: {
                name: values.firstName + " " + values.lastName,
                email: values.email,
                address: {
                  city: values.city,
                  line1: values.address,
                  postal_code: values.postCode,
                },
              },
            },
          })
          .then(function (response) {
            if (response["setupIntent"]["status"] === "succeeded") {
              const payment_method = response["setupIntent"]["payment_method"];
              const setDefaultPaymentMethod =
                process.env.REACT_APP_API_LINK + "set-default-payment-method";

              axios
                .post(setDefaultPaymentMethod, {
                  payment_method: payment_method,
                  client_id: client_id,
                })
                .then(function () {
                  let path = `/confirmation`;
                  history.push(path);
                });
            }
          });
      });
  };

  return (
    <div>
      <div className="d-none col-8 ml-auto mr-auto mt-4 d-md-flex justify-content-center">
        <StepJedha.Group unstackable fluid>
          <StepJedha active>
            <Icon name="user" />
            <StepJedha.Content>
              <StepJedha.Title>Registration information</StepJedha.Title>
            </StepJedha.Content>
          </StepJedha>
          <StepJedha>
            <Icon name="info" />
            <StepJedha.Content>
              <StepJedha.Title>Confirmation</StepJedha.Title>
            </StepJedha.Content>
          </StepJedha>
        </StepJedha.Group>
      </div>
      <div className="col-sm-12 col-md-8 m-auto">
        <FormJedha
          className="p-4 mt-5 d-flex flex-column justify-content-center"
          onSubmit={handleSubmit}
        >
          <section>
            <FieldTitle className="mb-4">
              Invoicing information{" "}
              <span role="img" aria-label="ok">
                👌
              </span>
            </FieldTitle>
            <Fieldset className="p-0 mb-5">
              <Row>
                <Col>
                  <Label className="d-flex col-12 align-items-center justify-content-center">
                    <span className="col-3 text-right">First name</span>
                    <Input
                      name="firstName"
                      className="col-8"
                      placeholder="Jean"
                      defaultValue={values.firstName}
                      onChange={handleChange}
                    />
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="d-flex col-12 align-items-center justify-content-center">
                    <span className="col-3 text-right">Last name</span>
                    <Input
                      name="lastName"
                      className="col-8"
                      placeholder="Valjean"
                      defaultValue={values.lastName}
                      onChange={handleChange}
                    />
                  </Label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Label className="d-flex col-12 align-items-center justify-content-center">
                    <span className="col-3 text-right">Email</span>
                    <Input
                      name="email"
                      className="col-8"
                      placeholder="jean@valjean.com"
                      defaultValue={values.email}
                      onChange={handleChange}
                    />
                  </Label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Label className="d-flex col-12 align-items-center justify-content-center">
                    <span className="col-3 text-right">Adress</span>
                    <Input
                      name="address"
                      className="col-8"
                      placeholder="2 rue Victor Hugo"
                      defaultValue={values.address}
                      onChange={handleChange}
                    />
                  </Label>
                </Col>
              </Row>

              <Row className="d-flex flex-sm-row flex-column justify-content-center">
                <Col className="col-md-4">
                  <Label className="m-0 d-flex col-12 align-items-center justify-content-center pl-lg-3">
                    <span className="col-sm-5 col-md-4 col-lg-3 text-right">
                      City
                    </span>
                    <Input
                      name="city"
                      className="col-8 pl-lg-4"
                      placeholder="Paris"
                      defaultValue={values.city}
                      onChange={handleChange}
                    />
                  </Label>
                </Col>

                <Col className="col-md-4">
                  <Label className="m-0 d-flex col-12 align-items-center justify-content-center">
                    <span className="p-0 col-sm-5 col-md-10 col-lg-5 text-right">
                      Post code
                    </span>
                    <Input
                      name="postCode"
                      className="col-8"
                      placeholder="75013"
                      defaultValue={values.postCode}
                      onChange={handleChange}
                    />
                  </Label>
                </Col>
              </Row>
            </Fieldset>
          </section>
          <section>
            <FieldTitle className="mb-4">
              Bank information{" "}
              <span role="img" aria-label="dollars">
                💸
              </span>
            </FieldTitle>
            <Fieldset className="d-flex flex-row align-items-center p-3">
              <div
                className="col-3 text-right"
                style={{
                  fontWeight: "bold",
                  color: "#1f4352",
                }}
              >
                <span className="">IBAN</span>
              </div>
              <div className="col-8">
                <IbanElement
                  id="iban"
                  options={{ supportedCountries: ["SEPA"] }}
                  className="w-100"
                />
              </div>
            </Fieldset>
            <Row>
              <Col>
                <ReferenceSepa className="mb-2 text-justify">
                  By signing this mandate form, you authorize (A) JEDHA SAS to
                  send instructions to your bank to debit your account, and (B)
                  your bank to debit your account in accordance with JEDHA SAS'
                  instructions. You have the right to be reimbursed by your bank
                  in accordance with the terms of your agreement with your bank.
                  A request for reimbursement must be made within 8 weeks of the
                  date your account is debited for an authorized debit.
                </ReferenceSepa>
              </Col>
            </Row>
          </section>
          <FormSubmit
            className="m-auto col-12"
            variant="primary"
            type="submit"
            disabled={
              !stripe ||
              !values.firstName ||
              !values.lastName ||
              !values.address ||
              !values.city ||
              !values.postCode
            }
          >
            S'INSCRIRE
          </FormSubmit>
        </FormJedha>
      </div>
    </div>
  );
};

export default CollectUserInfo;
